import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
  Switch,
  Upload,
  Button,
} from "antd";
import { useState } from "react";
import { useStore } from "../..";
import { observer } from "mobx-react-lite";

const { TextArea } = Input;

export const AddProperty = observer(() => {
  const { addNewProperty, addPropertyLoading } = useStore();
  const [type, setType] = useState("");
  const [special, setSpecial] = useState(false);
  const [status, setStatus] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [sqm, setSqm] = useState(0);
  const [rooms, setRooms] = useState(0);
  const [floor, setFloors] = useState(0);
  const [images, setImages] = useState([]);

  const handleFormSubmit = async () => {
    if (type === "house") {
      await addNewProperty(
        {
          special,
          status,
          title,
          description,
          propertyType: "house",
          propertyDetails: {
            sqm,
            rooms,
            floor,
          },
        },
        images
      );
    } else {
      await addNewProperty(
        {
          title,
          description,
          special,
          propertyType: "land",
          propertyDetails: {
            sqm,
          },
        },
        images
      );
    }
    resetAll();
  };

  const resetAll = () => {
    setType("");
    setSpecial(false);
    setStatus("");
    setTitle("");
    setDescription("");
    setSqm(0);
    setRooms(0);
    setFloors(0);
    setImages([]);
  };

  return (
    <>
      <Form
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 14,
        }}
        layout="horizontal"
        style={{
          maxWidth: 600,
        }}
      >
        <Form.Item label="Property Type">
          <Radio.Group value={type} onChange={(e) => setType(e.target.value)}>
            <Radio value="house">House</Radio>
            <Radio value="landscape">Landscape</Radio>
          </Radio.Group>
        </Form.Item>
        {type.length ? (
          <>
            <Form.Item label="Title">
              <Input
                required
                style={{ backgroundColor: "white" }}
                onChange={(e) => setTitle(e.target.value)}
              />
            </Form.Item>
            <Form.Item label="Description">
              <TextArea
                required
                rows={4}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Form.Item>
            {type === "house" && (
              <Form.Item label="Status">
                <Select required onChange={(e) => setStatus(e)}>
                  <Select.Option value="sale">Sale</Select.Option>
                  <Select.Option value="rental">Rental</Select.Option>
                </Select>
              </Form.Item>
            )}
            {type === "house" && (
              <>
                <Form.Item label="Rooms">
                  <InputNumber
                    defaultValue={rooms}
                    onChange={(e) => setRooms(e)}
                  />
                </Form.Item>
                <Form.Item label="Floors">
                  <InputNumber
                    defaultValue={floor}
                    onChange={(e) => setFloors(e)}
                  />
                </Form.Item>
              </>
            )}
            <Form.Item label="Sqm">
              <InputNumber defaultValue={sqm} onChange={(e) => setSqm(e)} />
            </Form.Item>

            <Form.Item label="Special" valuePropName="checked">
              <Switch onChange={(e) => setSpecial(e)} />
            </Form.Item>
            <Upload
              beforeUpload={() => false}
              onChange={(e) => setImages(e.fileList)}
              listType="picture-card"
            >
              <div>
                <PlusOutlined />
                <div
                  style={{
                    marginTop: 8,
                  }}
                >
                  Upload
                </div>
              </div>
            </Upload>
            <Form.Item>
              <Button
                loading={addPropertyLoading}
                onClick={handleFormSubmit}
                type="primary"
              >
                Add Property
              </Button>
            </Form.Item>
          </>
        ) : null}
      </Form>
    </>
  );
});
