import * as React from "react";
import "./style.css";
import { observer } from "mobx-react-lite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faDollarSign,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";
import { FeaturedProperties } from "../../components/featured-property-section/FeaturedProperties";
import { useStore } from "../..";
import { Modal } from "../../components/modal/Modal";
import { Link } from "react-router-dom";

export const HomePage = observer(() => {
  const { openModal } = useStore();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        width: "100%",
        margin: "auto",
      }}
    >
      {openModal && <Modal />}
      <HomePageHeader />
      <FeaturedProperties />
    </div>
  );
});

const HomePageHeader = () => {
  return (
    <>
      <section className="first-section">
        <div className="first-section-content">
          <h1 className="first-section-title">Find Your Dream Home</h1>
          <p className="first-section-description">
            Discover a wide range of properties for sale or rent. Explore our
            listings and find the perfect home for you.
          </p>
          <Link to="/listings" className="first-section-button">
            View Listings
          </Link>
        </div>
      </section>

      <section className="second-section">
        <div className="second-section-content">
          <h2 className="second-section-title">Why Choose Us?</h2>
          <p className="second-section-description">
            Choose our real estate company for a seamless experience backed by experienced professionals, 
            extensive property options, exceptional customer service,
            and a commitment to integrity. We make your dreams a reality.
          </p>
          <div className="second-section-features">
            <div className="feature">
              <FontAwesomeIcon icon={faHome} className="feature-icon" />
              <p className="feature-description">Wide Range of Properties</p>
            </div>
            <div className="feature">
              <FontAwesomeIcon icon={faDollarSign} className="feature-icon" />
              <p className="feature-description">Competitive Pricing</p>
            </div>
            <div className="feature">
              <FontAwesomeIcon icon={faCheckCircle} className="feature-icon" />
              <p className="feature-description">Trusted Agents</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
