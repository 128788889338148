import React from 'react';
import './style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

export const Contact = () => {
    const handleEmailButtonClick = () => {
        window.location.href = 'mailto:valeria@grecogrp.com';
    };

    return (
        <div className="contact-container">
            <h2>Contact Us</h2>
            <div className="contact-info">
                <h3>Sales and Rent</h3>
                <ul>
                    <li>
                        <FontAwesomeIcon icon={faPhone} />
                        Abuakell Sleiman: 00306949507177
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faEnvelope} />
                        Email: sleiman@grecogrp.com
                    </li>
                </ul>
                <ul>
                    <li>
                        <FontAwesomeIcon icon={faPhone} />
                        Abuakell Andrias: 00972528921454
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faEnvelope} />
                        Email: andrias@grecogrp.com
                    </li>
                </ul>
                <ul>
                    <li>
                        <FontAwesomeIcon icon={faPhone} />
                        Abuakell Kraiem: 00972522415697, 0097249562936
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faEnvelope} />
                        Email: kraiem@grecogrp.com
                    </li>
                </ul>
                <h3>Design & Construction</h3>
                <ul>
                    <li>
                        <FontAwesomeIcon icon={faPhone} />
                        Abuakell Sleiman: 00306949507177
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faEnvelope} />
                        Email: sleiman@grecogrp.com
                    </li>
                </ul>
                <h3>Travel Organization and Property Tour</h3>
                <ul>
                    <li>
                        <FontAwesomeIcon icon={faEnvelope} />
                        Novara Valeria: 00306974760400
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faEnvelope} />
                        Email: valeria@grecogrp.com
                    </li>
                </ul>
                <h3>Property Management</h3>
                <ul>
                    <li>
                        <FontAwesomeIcon icon={faPhone} />
                        Abuakell Sleiman: 00306949507177
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faEnvelope} />
                        Email: sleiman@grecogrp.com
                    </li>
                </ul>
                <ul>
                    <li>
                        <FontAwesomeIcon icon={faPhone} />
                        Abuakell Kraiem: 00972522415697, 0097249562936
                    </li>
                    <li>
                        <FontAwesomeIcon icon={faEnvelope} />
                        Email: kraiem@grecogrp.com
                    </li>
                </ul>
                <h3>Locations</h3>
                <ul>
                    <li>
                        <FontAwesomeIcon icon={faMapMarkerAlt} />
                        Kfar Yassif, Israel.
                        <br />
                        P.O.BOX: 1517, Area Code 2490800
                    </li>
                </ul>
                <ul>
                    <li>
                        <FontAwesomeIcon icon={faMapMarkerAlt} />
                        Leoforos halkoutsiou 50, Greece.
                        <br />
                        Skala Oropos, 19015.
                    </li>
                </ul>
            </div>
            <div className="contact-action">
                <p>If you want to contact us, please press this button to contact us.</p>
                <button className="contact-button" onClick={handleEmailButtonClick}>
                    Contact
                </button>
            </div>
        </div>
    );
};
