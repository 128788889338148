import React from "react";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarker,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";

export const Footer = () => {
  return (
    <footer className="footer-distributed">
      <div className="footer-left">
        <img
          style={{ width: 200, height: 200, mixBlendMode: "darken" }}
          src="https://firebasestorage.googleapis.com/v0/b/greco-app.appspot.com/o/test.png?alt=media&token=bd1d7f67-acce-4ba9-9310-8c58126cc39d"
        />

        <p className="footer-links">
          <Link to="/" className="link-1">
            Home
          </Link>
          <Link to="/listings">Listings</Link>
          <Link to="/contact">Contact</Link>
          <Link to="/construction">Construction</Link>
        </p>

        <p className="footer-company-name">Greco Investments</p>
      </div>

      <div className="footer-center">
        <div>
          <FontAwesomeIcon icon={faMapMarker} />
          <p>
            <span>
              Location: Kfar Yassif, Israel, P.O Box: 1517, Area Code: 2490800.
            </span>
          </p>
        </div>

        <div>
          <FontAwesomeIcon icon={faMapMarker} />
          <p>
            <span>
              Location: Leoforos Halkoutsiou 50, Skala Oropos , 19015, Greece.
            </span>
          </p>
        </div>

        <div>
          <FontAwesomeIcon icon={faPhone} />
          <p>Phone: 0097249562936</p>
        </div>
      </div>

      <div className="footer-right">
        <p className="footer-company-about">
          <span>About the company</span>
          Greco Investments - Real Estate Company
        </p>

        <div className="footer-icons">
          <a href="https://www.facebook.com/grecoinvestments">
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
          <a href="https://www.instagram.com/grecoinvestments2023/">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </div>
      </div>
    </footer>
  );
};
