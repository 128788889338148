import React from "react";
import { HomePage } from "./app-components/home-page/HomePage";
import { ListingPage } from "./app-components/listing-page/ListingPage";
import { Contact } from "./app-components/contact-page/Contact";
import { Construction } from "./app-components/construction-page/Construction";
import { AdminPage } from "./app-components/admin-page/AdminPage";

export const routes = [
  { title: "Home", path: "/", component: <HomePage /> },
  { title: "Listing", path: "/listings", component: <ListingPage /> },
  { title: "Contact", path: "/contact", component: <Contact /> },
  { title: "Construction", path: "/construction", component: <Construction /> },
  { title: "Admin Page", path: "/admin", component: <AdminPage /> },
];
