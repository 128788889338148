import React from "react";
// import ImageGallery from "react-image-gallery";
import "./style.css";
import "react-image-gallery/styles/css/image-gallery.css";

export const Construction = () => {
    const galleries = [
      {
        id: 1,
        title: "Gallery 1",
        description: "Gallery 1 Description",
        images: [
          {
            original: "https://picsum.photos/id/1018/800/400/",
            thumbnail: "https://picsum.photos/id/1018/250/150/",
          },
          {
            original: "https://picsum.photos/id/1015/800/400/",
            thumbnail: "https://picsum.photos/id/1015/250/150/",
          },
          {
            original: "https://picsum.photos/id/1019/800/400/",
            thumbnail: "https://picsum.photos/id/1019/250/150/",
          },
        ],
      },
      {
        id: 2,
        title: "Gallery 2",
        description: "Gallery 2 Description",
        images: [
          {
            original: "https://picsum.photos/id/1018/800/400/",
            thumbnail: "https://picsum.photos/id/1018/250/150/",
          },
          {
            original: "https://picsum.photos/id/1015/800/400/",
            thumbnail: "https://picsum.photos/id/1015/250/150/",
          },
          {
            original: "https://picsum.photos/id/1019/800/400/",
            thumbnail: "https://picsum.photos/id/1019/250/150/",
          },
        ],
      },
    ];

  return (
    <div className="gallery-container">
      <h1>
        SOON
      </h1>
        {/* {galleries.map((gallery) => (
            <div key={gallery.id} className="gallery">
                <h2 className="gallery-title">{gallery.title}</h2>
                {gallery.description && <p className="gallery-description">{gallery.description}</p>}
                <ImageGallery items={gallery.images} />
            </div>
        ))} */}
    </div>
  );
};
