import React from "react";
import "./App.css";
import { Layout } from "antd";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { routes } from "./routes";
import { useStore } from ".";
import { Loading } from "./components/loading/Loading";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { Footer } from "./app-components/footer/Footer";
import DrawerAppBar from "./app-components/app-bar/AppBar";

function App() {
  const { loading, fetchProperties, getallProperties, ifAdminPage } =
    useStore();

  useEffect(() => {
    if (!getallProperties.length) fetchProperties();
  }, []);

  return (
    <BrowserRouter>
      <Layout>
        {loading ? (
          <Loading />
        ) : (
          <>
            <DrawerAppBar />
            <Routes>
              {routes.map((route, key) => (
                <Route key={key} path={route.path} element={route.component} />
              ))}
            </Routes>
            {!ifAdminPage && <Footer />}
          </>
        )}
      </Layout>
    </BrowserRouter>
  );
}

export default observer(App);
