import React from "react";
import "./style.css";
import { Divider } from "antd";
import { useStore } from "../..";
import { observer } from "mobx-react-lite";
import { Card, Button } from "antd";
import { Modal } from "../../components/modal/Modal";
import { SearchBar } from "../../components/search-bar/SearchBar";
const { Meta } = Card;

export const ListingPage = observer(() => {
  const { getallProperties, getSearchedProperties, openModal } = useStore();

  return (
    <div className="container">
      <Divider orientation="left" style={{ fontWeight: "bold" }}>
        Our Listing
      </Divider>
      <SearchBar />
      {openModal && <Modal />}
      <div className="card-grid">
        {getSearchedProperties.length
          ? getSearchedProperties.map((prop, index) => (
              <ItemCard key={index} property={prop} />
            ))
          : getallProperties.map((prop, index) => (
              <ItemCard key={index} property={prop} index={index} />
            ))}
      </div>
    </div>
  );
});

const ItemCard = ({ property, index }) => {
  const { setOpenModal, setSelectedCardtoOpen } = useStore();
  return (
    <Card
      cover={<img alt="Property" src={property.images[0]} />}
      bodyStyle={{ padding: "12px 16px" }}
    >
      <Meta title={property.propertyType.toUpperCase()} />
      <Meta title={property.title} description={property.description} />
      <div style={{ marginTop: 16 }}>
        {property.status && (
          <p style={{ marginBottom: 8 }}>
            <strong>Status:</strong> {property.status}
          </p>
        )}
      </div>
      <Button
        onClick={() => {
          console.log(property.id);
          setSelectedCardtoOpen(property.id);
          setOpenModal(true);
        }}
        type="primary"
        style={{
          backgroundColor: "#e17055",
          color: "#fff",
          position: "absolute",
          bottom: 16,
          left: 16,
          right: 16,
        }}
      >
        More Info
      </Button>
    </Card>
  );
};
