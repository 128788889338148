import { action, observable, makeObservable, toJS, computed } from "mobx";
import { fetchAllProperties, addProperty, deleteProperty } from "../data/data";

export class AppStore {
  allProperties = [];
  searchRes = [];
  images = [];
  loading = false;
  openModal = false;
  selectedCardDetails = {};
  searchedProperties = [];
  ifAdminPage = false;
  addPropertyLoading = false;

  constructor() {
    makeObservable(this, {
      selectedCardDetails: observable,
      addPropertyLoading: observable,
      ifAdminPage: observable,
      loading: observable,
      images: observable,
      allProperties: observable,
      searchRes: observable,
      searchedProperties: observable,
      openModal: observable,
      fetchProperties: action.bound,
      getallProperties: computed,
      setOpenModal: action.bound,
      setSelectedCardtoOpen: action.bound,
      handlePropertySearch: action.bound,
      resetSearch: action.bound,
      setIfAdmin: action.bound,
      addNewProperty: action.bound,
      handleItemDelete: action.bound,
    });
  }

  setIfAdmin(status) {
    this.ifAdminPage = status;
  }

  async handleItemDelete(itemId) {
    this.allProperties = this.allProperties.filter(
      (item) => item.id !== itemId
    );

    try {
      const deleteResponse = await deleteProperty(itemId);
      console.log(deleteResponse);
    } catch (err) {
      console.log(err);
    }
  }

  handlePropertySearch(rooms, floor, status, type, sqmfrom, sqmto) {
    this.searchedProperties = this.getallProperties.filter(function (property) {
      if (type && property.propertyType !== type) return false;
      if (sqmfrom && property.propertyDetails.sqm < sqmfrom) return false;
      if (sqmto && property.propertyDetails.sqm > sqmto) return false;
      if (rooms && property.propertyDetails.rooms !== rooms) return false;
      if (floor && property.propertyDetails.floor !== floor) return false;
      if (status && property.status !== status) return false;
      return true;
    });
    console.log(toJS(this.searchedProperties));
  }

  async addNewProperty(newProperty, images) {
    this.addPropertyLoading = true;
    const formData = new FormData();
    for (let i = 0; i < images.length; i++) {
      formData.append("images", images[i].originFileObj);
    }
    formData.append("content", JSON.stringify(newProperty));
    try {
      const { success, data } = await addProperty(formData);
      if (success) {
        this.allProperties.push(data);
      }
      this.addPropertyLoading = false;
    } catch (err) {
      this.handleError(err);
    }
  }

  async fetchProperties() {
    this.loading = true;
    try {
      let properties = await fetchAllProperties();
      this.allProperties = [];
      for (let i = 0; i < properties.length; i++) {
        if (properties[i]) this.allProperties.push(JSON.parse(properties[i]));
      }
      console.log(toJS(this.allProperties));
      this.loading = false;
    } catch (err) {
      this.handleError(err.message);
    }
  }

  resetSearch() {
    this.searchedProperties = [];
  }

  setSelectedCardtoOpen(cardId) {
    const selectedCard = this.allProperties.filter(
      (prop) => prop.id === cardId
    );
    this.selectedCardDetails = selectedCard[0];
  }

  setOpenModal(status) {
    this.openModal = status;
  }

  handleError(err) {
    console.log(err);
  }

  get getallProperties() {
    return toJS(this.allProperties);
  }

  get getSearchedProperties() {
    return toJS(this.searchedProperties);
  }
}
