import React, { useState } from "react";
import { Form, InputNumber, Select, Button } from "antd";
import { useStore } from "../..";
import "./style.css";

export const SearchBar = () => {
  const { handlePropertySearch, resetSearch } = useStore();

  const [type, setType] = useState("");
  const [status, setStatus] = useState("rental");
  const [sqmfrom, setSqmfrom] = useState();
  const [sqmto, setSqmto] = useState();
  const [rooms, setRooms] = useState();
  const [floor, setFloor] = useState();

  const handleSearch = () => {
    handlePropertySearch(rooms, floor, status, type, sqmfrom, sqmto);
  };

  const handleReset = () => {
    setStatus("");
    setType("");
    setSqmto();
    setSqmfrom();
    setRooms();
    setFloor();
    resetSearch();
  };

  return (
    <div className="wrapper">
      <div className="inputs-wrapper">
        <Form.Item labelAlign="" label="Property Type">
          <Select
            value={type}
            onChange={setType}
            style={{ width: "200px", margin: "0 auto" }}
          >
            <Select.Option value="house">House</Select.Option>
            <Select.Option value="land">Landscape</Select.Option>
          </Select>
        </Form.Item>
        <div className="inner">
          {type === "house" && (
            <div className="first-inputs-wrapper">
              <div style={{ marginRight: "12px" }}>
                <Form.Item label="Status">
                  <Select
                    value={status}
                    onChange={setStatus}
                    style={{ width: "200px", margin: "0 auto" }}
                  >
                    <Select.Option value="rental">Rental</Select.Option>
                    <Select.Option value="sale">Sale</Select.Option>
                  </Select>
                </Form.Item>
              </div>
              <div style={{ marginRight: "12px" }}>
                <Form.Item label="Rooms">
                  <InputNumber value={rooms} onChange={setRooms} />
                </Form.Item>
              </div>
              <div style={{ marginRight: "12px" }}>
                <Form.Item label="Floors">
                  <InputNumber value={floor} onChange={setFloor} />
                </Form.Item>
              </div>
            </div>
          )}
          {type && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Form.Item label="Sq.m from">
                <InputNumber value={sqmfrom} onChange={setSqmfrom} />
              </Form.Item>
              <Form.Item label="Sq.m to">
                <InputNumber value={sqmto} onChange={setSqmto} />
              </Form.Item>
            </div>
          )}
        </div>
      </div>
      <div className="btn-wrapper">
        <div style={{ margin: 10 }}>
          <Button
            disabled={!type}
            style={{ backgroundColor: "#e17055", color: "#fff", width: 150 }}
            type="primary"
            block
            onClick={handleSearch}
          >
            Search
          </Button>
        </div>
        <div style={{ margin: 10 }}>
          <Button
            style={{ backgroundColor: "#3498db", color: "#fff", width: 150 }}
            type="primary"
            block
            onClick={handleReset}
          >
            Reset Search
          </Button>
        </div>
      </div>
    </div>
  );
};
