import React, { useEffect } from "react";
import { useStore } from "../..";
import { Image, Descriptions } from "antd";
import "./style.css";
import { useState } from "react";

export const Modal = () => {
  const { selectedCardDetails, setOpenModal } = useStore();
  const [layout, setLayout] = useState("vertical");

  useEffect(() => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 578) setLayout("horizontal");
  }, []);

  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close-button" onClick={() => setOpenModal(false)}>
          &times;
        </span>
        <div className="modal-body">
          <div className="top">
            <Descriptions
              bordered
              contentStyle={{ fontSize: "1rem" }}
              labelStyle={{ fontSize: "1rem", fontWeight: "bold" }}
              size="large"
              title="PROPERTY INFO"
              layout={layout}
              className="description"
            >
              <Descriptions.Item label="Title">
                {selectedCardDetails.title}
              </Descriptions.Item>
              <Descriptions.Item label="Description">
                {selectedCardDetails.description}
              </Descriptions.Item>
              {selectedCardDetails.status && (
                <Descriptions.Item label="Status">
                  {selectedCardDetails.status}
                </Descriptions.Item>
              )}
              {selectedCardDetails.propertyDetails.rooms && (
                <Descriptions.Item label="Rooms">
                  {selectedCardDetails.propertyDetails.rooms}
                </Descriptions.Item>
              )}
              {selectedCardDetails.propertyDetails.sqm && (
                <Descriptions.Item label="Sq.m">
                  {selectedCardDetails.propertyDetails.sqm}
                </Descriptions.Item>
              )}
              {selectedCardDetails.propertyDetails.floor && (
                <Descriptions.Item label="Floor">
                  {selectedCardDetails.propertyDetails.floor}
                </Descriptions.Item>
              )}
            </Descriptions>
          </div>
          <div className="bottom">
            {selectedCardDetails.images.map((img, index) => (
              <Image
                key={index}
                style={{
                  zIndex: 9999,
                }}
                height={200}
                width={200}
                src={img}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
