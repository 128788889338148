import React from "react";
import { Space, Spin } from "antd";
export const Loading = () => (
  <Space
    direction="vertical"
    style={{
      width: "100%",
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    }}
  >
    <Spin tip="Loading.." size="large">
      <div
        style={{
          padding: "50px",
          background: "rgba(0,0,0,0.05)",
          borderRadius: "4px",
        }}
      />
    </Spin>
  </Space>
);
