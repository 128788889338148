import axios from 'axios';

const URL = `https://${process.env.API_URL || 'grecogrp.com'}/api`;

export const fetchAllProperties = () =>
	axios
		.get(URL)
		.then((res) => res.data)
		.catch((err) => err);

export const addProperty = (property) =>
	axios
		.post(URL, property, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		})
		.then((res) => res.data)
		.catch((err) => err);

export const deleteProperty = (propertyId) =>
	axios
		.delete(`${URL}/${propertyId}`)
		.then((res) => res.data)
		.catch((err) => err);
