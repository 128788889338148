import React from "react";
import { useRef, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FeaturedPropertyCard } from "../featured-property-card/FeaturedPropertyCard";
import "./style.css";
import { useStore } from "../..";
import { observer } from "mobx-react-lite";

export const FeaturedProperties = observer(() => {
  const { getallProperties } = useStore();
  const sliderRef = useRef();

  const goToPrevSlide = () => {
    sliderRef.current.slickPrev();
  };

  const goToNextSlide = () => {
    sliderRef.current.slickNext();
  };

  const PrevArrow = (props) => (
    <button className="slick-arrow slick-prev" onClick={props.onClick}>
      <FontAwesomeIcon icon={faArrowLeft} />
    </button>
  );

  const NextArrow = (props) => (
    <button className="slick-arrow slick-next" onClick={props.onClick}>
      <FontAwesomeIcon icon={faArrowRight} />
    </button>
  );

  const updateSettings = () => {
    const screenWidth = window.innerWidth;
    const updatedSettings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      prevArrow: <PrevArrow />,
      nextArrow: <NextArrow />,
    };
    if (screenWidth < 1300 && screenWidth > 1000) {
      updatedSettings.slidesToShow = 3;
    }

    if (screenWidth < 1000) {
      updatedSettings.slidesToShow = 2;
    }

    if (screenWidth < 720) updatedSettings.slidesToShow = 1;

    return updatedSettings;
  };

  useEffect(() => {
    const handleResize = () => {
      sliderRef.current.slickGoTo(0, true);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className="third-section">
      <div className="third-section-content">
        <h2 className="third-section-title">Featured Properties</h2>
        <div className="slider-container">
          <Slider {...updateSettings()} ref={sliderRef}>
            {getallProperties.map((prop) => {
              if (prop.special)
                return <FeaturedPropertyCard key={prop.id} property={prop} />;
              else return null;
            })}
          </Slider>
          <div className="slider-controls">
            <button className="slider-control-btn prev" onClick={goToPrevSlide}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
            <button className="slider-control-btn next" onClick={goToNextSlide}>
              <FontAwesomeIcon icon={faArrowRight} />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
});
