import React from "react";
import "./style.css";
import { useStore } from "../..";

export const FeaturedPropertyCard = ({ property }) => {
  const { setOpenModal, setSelectedCardtoOpen } = useStore();
  return (
    <div
      className="property"
      onClick={() => {
        setSelectedCardtoOpen(property.id);
        setOpenModal(true);
      }}
    >
      {property.status && (
        <span
          className={`property-label ${property.status}`}
        >{`For ${property.status}`}</span>
      )}
      <img src={property.images[0] || ""} alt="" className="property-image" />
      <div className="property-details">
        <h3 className="property-title">{property.title}</h3>
        <h6 className="property-description">{property.description}</h6>
      </div>
    </div>
  );
};
