import React, { useEffect, useState } from "react";
import { Select, Button, Popover, Input } from "antd";
import { useStore } from "../..";
import { Avatar, List } from "antd";
import "./style.css";
import { Tabs } from "antd";
import { observer } from "mobx-react-lite";
import { AddProperty } from "./AddProperty";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

const userCred = {
  user: "grecoadmin",
  password: "grecoadmin",
};

export const AdminPage = observer(() => {
  const { setIfAdmin, getallProperties, handleItemDelete } = useStore();
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const onChange = (key) => {
    console.log(key);
  };

  const items = [
    {
      key: "1",
      label: `All Properties`,
      children: getallProperties.map((prop, index) => (
        <AllProperties
          deleteItem={(itemId) => handleItemDelete(itemId)}
          key={index}
          item={prop}
        />
      )),
    },
    {
      key: "2",
      label: `Add Property`,
      children: <AddProperty />,
    },
  ];

  useEffect(() => {
    setIfAdmin(true);
    return () => {
      setIfAdmin(false);
    };
  }, []);

  if (!loggedIn) {
    return (
      <div
        style={{
          width: "50%",
          height: "100vh",
          margin: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "350px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            textAlign: "center",
            padding: "10px",
            boxShadow:
              " rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
          }}
        >
          <h2>Login</h2>
          {error && <p>{error}</p>}
          <Input
            style={{ width: "50%", margin: "auto" }}
            prefix={<UserOutlined />}
            size="large"
            placeholder="Enter username.."
            onChange={(e) => setUser(e.target.value)}
          />
          <Input
            style={{ width: "50%", margin: "auto" }}
            size="large"
            type="password"
            prefix={<LockOutlined />}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            size="large"
            style={{
              background: "#E17055",
              color: "white",
              width: "50%",
              margin: "auto",
            }}
            onClick={() => {
              if (user === userCred.user && password === userCred.password)
                setLoggedIn(true);
              else setError("Invalid username or password!");
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    );
  }

  return (
    <Tabs
      style={{ margin: "auto", marginTop: 60, width: "80%" }}
      defaultActiveKey="1"
      items={items}
      onChange={onChange}
    />
  );
});

const AllProperties = ({ item, deleteItem }) => {
  const [open, setOpen] = useState(false);

  const hide = () => {
    setOpen(false);
  };

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  return (
    <List className="demo-loadmore-list" itemLayout="horizontal">
      <List.Item
        fontSize={20}
        actions={[
          <Popover
            content={
              <div
                style={{
                  width: "50%",
                  margin: "auto",
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
              >
                <a onClick={hide}>No</a>
                <a
                  onClick={() => {
                    hide();
                    deleteItem(item.id);
                  }}
                >
                  Yes
                </a>
              </div>
            }
            title="Are you sure you want to delete this property ?"
            trigger="click"
            open={open}
            onOpenChange={handleOpenChange}
          >
            <Button type="primary">Delete</Button>
          </Popover>,
        ]}
      >
        <List.Item.Meta
          avatar={<Avatar src={item.images[0]} />}
          title={item.title}
          description={item.description}
        />
      </List.Item>
    </List>
  );
};
